import { Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CmBaseCompoundFilterIntl, CmObjectFieldList, CmObjectFieldListData, CmObjectFieldListIntl, CmObjectListData, CmPaginateTableIntl, CmUiCompoundFilterIntl, CmUiTableIntl, TopBarIntl } from '@cm/ui-modules';
import { CookieService } from 'ngx-cookie-service';
import { Observable, combineLatest, map, switchMap, tap } from 'rxjs';
import { LANG } from './core/injectors';
import { GetCustomFieldDefinitionResponseGQL, GetFieldsGQL } from './shared/graphql';
import { ObjectName } from './shared/models';
import { GetFieldsService } from './shared/services/get-fields.service';
import { ObjectListDataService } from './shared/services/object-list-data.service';
import { FrontendTranslateService } from './shared/services/translation.service';
import { InactivityMonitorService } from './shared/services/inactivitymonitor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy{
  translateReady$: Observable<boolean>;
  private currentLocale: string;

  constructor(
    elementRef: ElementRef,
    translate: FrontendTranslateService,
    cmObjectListData: CmObjectListData,
    cmObjectFieldList: CmObjectFieldList,
    objectListDataService: ObjectListDataService,
    getFieldsService: GetFieldsService,
    cookieService: CookieService,
    cmUiCompoundFilterIntl: CmUiCompoundFilterIntl,
    cmUiTableIntl: CmUiTableIntl,
    panelTopBarIntl: TopBarIntl,
    paginatorIntl: MatPaginatorIntl,
    filterIntl: CmBaseCompoundFilterIntl,
    paginationTableIntl: CmPaginateTableIntl,
    objectFieldIntl: CmObjectFieldListIntl,
    cmObjectFieldListData: CmObjectFieldListData,
    getCustomFieldDefinitionResponseGQL: GetCustomFieldDefinitionResponseGQL,
    getFieldsGQL: GetFieldsGQL,
    @Inject(LANG) lang: string,
    @Inject(LOCALE_ID) locale_id: string,
    private inactivityMonitor: InactivityMonitorService
  ) {
    const appRootElement = elementRef.nativeElement as HTMLElement;
    this.currentLocale = cookieService.get('locale') || 'en';
    lang = this.currentLocale;
    translate.onLangChange.subscribe(() => {
      translateFilter(filterIntl, translate);
      translateObjectField(objectFieldIntl, translate);
      translatePaginationTable(paginationTableIntl, translate);
      translatePaginator(paginatorIntl, translate);
      translatePanelTopBar(panelTopBarIntl, translate);
      translateUiTable(cmUiTableIntl, translate);
      translateFilters(cmUiCompoundFilterIntl, translate);
    });

    this.translateReady$ = combineLatest([
      translate.getTranslation('en'),
      translate.getTranslation(lang),
    ]).pipe(
      tap(() => translate.setDefaultLang('en')),
      switchMap(() => translate.use(lang)),
      tap(() => {
        translateFilter(filterIntl, translate);
        translateObjectField(objectFieldIntl, translate);
        translatePaginationTable(paginationTableIntl, translate);
        translatePaginator(paginatorIntl, translate);
        translatePanelTopBar(panelTopBarIntl, translate);
        translateUiTable(cmUiTableIntl, translate);
        translateFilters(cmUiCompoundFilterIntl, translate);
      }),
      map(()=>true),
    );

    cmObjectListData.getObjectList = (objectName, searchValue, selectedIds) =>
      objectListDataService.getObjectList(objectName as ObjectName, searchValue, selectedIds);

    cmObjectFieldList.getObjectFields = objectName =>
      getFieldsService.getFieldsToObjectFieldOption(objectName as ObjectName);


      cmObjectFieldListData.getObjectCustomFields = objectName =>
      getCustomFieldDefinitionResponseGQL
        .fetch({ objectName }, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
        .pipe(map(response => response.data));
    
      cmObjectFieldListData.getObjectDefaultFields = objectName =>
      getFieldsGQL
        .fetch({ objectName }, { fetchPolicy: 'no-cache', errorPolicy: 'all' })
        .pipe(map(response => response.data));
      
  }


  ngOnInit() {
    this.inactivityMonitor.startMonitoring();
  }

  ngOnDestroy() {
    this.inactivityMonitor.stopMonitoring();
  }
}









//
function translateFilter(intl: CmBaseCompoundFilterIntl, service: FrontendTranslateService): void {
  intl.lessThan = service.instant('ADMIN.operator.lessThan');
  intl.greaterThan = service.instant('ADMIN.operator.greaterThan');
  intl.lessThanOrEqualTo = service.instant('ADMIN.operator.lessThanOrEqualTo');
  intl.greaterThanOrEqualsTo = service.instant('ADMIN.operator.greaterThanOrEqualsTo');
  intl.equals = service.instant('ADMIN.operator.equals');
  intl.notEquals = service.instant('ADMIN.operator.notEquals');
  intl.startsWith = service.instant('ADMIN.operator.startsWith');
  intl.endWith = service.instant('ADMIN.operator.endWith');
  intl.contains = service.instant('ADMIN.operator.contains');
  intl.exists = service.instant('ADMIN.operator.exists');
  intl.notStartsWith = service.instant('ADMIN.operator.notStartsWith');
  intl.notEndsWith = service.instant('ADMIN.operator.notEndsWith');
  intl.notContains = service.instant('ADMIN.operator.notContains');
  intl.notExists = service.instant('ADMIN.operator.notExists');
  intl.and = service.instant('ADMIN.common.filter.and');
  intl.or = service.instant('ADMIN.common.filter.or');
  intl.value = service.instant('ADMIN.common.filter.value');
  intl.operator = service.instant('ADMIN.common.filter.operator');
  intl.changes.next();
}

function translateObjectField(intl: CmObjectFieldListIntl, service: FrontendTranslateService) {
  intl.objectName = service.instant('ADMIN.common.filter.objectName');
  intl.objectField = service.instant('ADMIN.common.filter.objectField');
  intl.filter = service.instant('ADMIN.common.filter.filter');
  intl.reset = service.instant('ADMIN.common.filter.reset');
  intl.defineFilter = service.instant('ADMIN.common.filter.defineFilter');
  intl.value = service.instant('ADMIN.common.filter.value');
  intl.yes = service.instant('ADMIN.common.filter.yes');
  intl.no = service.instant('ADMIN.common.filter.no');
  intl.getObjectName = objectName => service.instant(`API.${objectName}Label`);
  intl.getPropertyName = (objectName, propertyName) =>
    service.instant(`API.${objectName}.${propertyName}`);
  intl.getPropertyValue = (objectName, propertyName, value) =>
    service.instant(`API.${objectName}.${propertyName}.${value}`);
  intl.changes.next();
}

function translatePaginationTable(intl: CmPaginateTableIntl, service: FrontendTranslateService) {
  intl.noData = service.instant('ADMIN.common.table.noData');
  intl.filter = service.instant('ADMIN.common.table.filter');
  intl.columns = service.instant('ADMIN.common.table.columns');
  intl.actions = service.instant('ADMIN.common.table.actions');
  intl.search = service.instant('ADMIN.common.table.search');
  intl.changes.next();
}

function translatePaginator(intl: MatPaginatorIntl, service: FrontendTranslateService): void {
  intl.itemsPerPageLabel = service.instant('paginator.itemsPerPageLabel');
  intl.nextPageLabel = service.instant('paginator.nextPageLabel');
  intl.previousPageLabel = service.instant('paginator.previousPageLabel');
  intl.firstPageLabel = service.instant('paginator.firstPageLabel');
  intl.lastPageLabel = service.instant('paginator.lastPageLabel');
  intl.changes.next();
}

function translatePanelTopBar(intl: TopBarIntl, service: FrontendTranslateService): void {
  intl.accountSettings = service.instant('ADMIN.accountSettings');
  intl.logout = service.instant('ADMIN.logout');
  intl.presentationsManager = service.instant('ADMIN.presentationsManager');
  intl.highpManager = service.instant('ADMIN.highpManager');
  intl.datusoManager = service.instant('ADMIN.datusoManager');
  intl.challengeManager = service.instant('ADMIN.challengeManager');
  intl.courseManager = service.instant('ADMIN.courseManager');
  intl.contactManager = service.instant('ADMIN.contactManager');
  intl.automator = service.instant('ADMIN.automator');
  intl.sfdc = service.instant('ADMIN.sfdc');
  intl.administrationManager = service.instant('ADMIN.administrationManager');
  intl.presentations = service.instant('ADMIN.presentations');
  intl.meetings = service.instant('ADMIN.meetings');
  intl.presentationMedia = service.instant('ADMIN.presentationMedia');
  intl.highpCampaigns = service.instant('ADMIN.highpCampaigns');
  intl.highpMedia = service.instant('ADMIN.highpMedia');
  intl.datusoCampaigns = service.instant('ADMIN.datusoCampaigns');
  intl.datusoForms = service.instant('ADMIN.datusoForms');
  intl.courses = service.instant('ADMIN.courses');
  intl.courseLibraries = service.instant('ADMIN.courseLibraries');
  intl.courseCategories = service.instant('ADMIN.courseCategories');
  intl.leads = service.instant('ADMIN.leads');
  intl.contacts = service.instant('ADMIN.contacts');
  intl.workplaces = service.instant('ADMIN.workplaces');
  intl.groups = service.instant('ADMIN.groups');
  intl.specializations = service.instant('ADMIN.specializations');
  intl.customFields = service.instant('ADMIN.customFields');
  intl.teamMembers = service.instant('ADMIN.teamMembers');
  intl.batchJobs = service.instant('ADMIN.batchJobs');
  intl.batchJobsContactManager = service.instant('ADMIN.batchJobsContactManager');
  intl.applicationVersions = service.instant('ADMIN.applicationVersions');
  intl.billing = service.instant('ADMIN.billing');
}
function translateUiTable(intl: CmUiTableIntl, translate: FrontendTranslateService) {
  intl.filterInUse = translate.instant('uiTable.filterInUse');
  intl.search = translate.instant('uiTable.search');
  intl.columns = translate.instant('uiTable.columns');
  intl.basicColumns = translate.instant('uiTable.basicColumns');
  intl.customColumns = translate.instant('uiTable.customColumns');
  intl.basicMobileColumns = translate.instant('uiTable.basicMobileColumns');
  intl.customMobileColumns = translate.instant('uiTable.customMobileColumns');
  intl.apply = translate.instant('uiTable.apply');
}


function translateFilters(
  cmUiCompoundFilterIntl: CmUiCompoundFilterIntl,
  translateService: FrontendTranslateService,
): void {
  cmUiCompoundFilterIntl.addFilter = translateService.instant('ADMIN.common.filter.defineFilter');
  cmUiCompoundFilterIntl.filter = translateService.instant('ADMIN.common.filter.filter');
  cmUiCompoundFilterIntl.reset = translateService.instant('ADMIN.common.filter.reset');
  cmUiCompoundFilterIntl.object = translateService.instant('ADMIN.common.filter.objectName');
  cmUiCompoundFilterIntl.field = translateService.instant('ADMIN.common.filter.objectField');
  cmUiCompoundFilterIntl.value = translateService.instant('ADMIN.common.filter.value');
  cmUiCompoundFilterIntl.operator = translateService.instant('ADMIN.common.filter.operator');
  cmUiCompoundFilterIntl.predefinedFilters = translateService.instant(
    'ADMIN.common.filter.predefinedFilters',
  );
  cmUiCompoundFilterIntl.contains = translateService.instant('ADMIN.operator.contains');
  cmUiCompoundFilterIntl.lessThan = translateService.instant('ADMIN.operator.lessThan');
  cmUiCompoundFilterIntl.greaterThan = translateService.instant('ADMIN.operator.greaterThan');
  cmUiCompoundFilterIntl.lessThanOrEqualTo = translateService.instant(
    'ADMIN.operator.lessThanOrEqualTo',
  );
  cmUiCompoundFilterIntl.equals = translateService.instant('ADMIN.operator.equals');
  cmUiCompoundFilterIntl.notEquals = translateService.instant('ADMIN.operator.notEquals');
  cmUiCompoundFilterIntl.startsWith = translateService.instant('ADMIN.operator.startsWith');
  cmUiCompoundFilterIntl.endWith = translateService.instant('ADMIN.operator.endWith');
  cmUiCompoundFilterIntl.exists = translateService.instant('ADMIN.operator.exists');
  cmUiCompoundFilterIntl.notStartsWith = translateService.instant('ADMIN.operator.notStartsWith');
  cmUiCompoundFilterIntl.notEndsWith = translateService.instant('ADMIN.operator.notEndsWith');
  cmUiCompoundFilterIntl.notContains = translateService.instant('ADMIN.operator.notContains');
  cmUiCompoundFilterIntl.notExists = translateService.instant('ADMIN.operator.notExists');
  cmUiCompoundFilterIntl.greaterThanOrEqualsTo = translateService.instant(
    'ADMIN.operator.greaterThanOrEqualsTo',
  );
  cmUiCompoundFilterIntl.isNull = translateService.instant('ADMIN.operator.isNull');
  cmUiCompoundFilterIntl.isNotNull = translateService.instant('ADMIN.operator.isNotNull');
}
