import { Routes } from '@angular/router';
import { AccessGuard, AuthenticationGuard } from '@cm/ui-modules';

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        data: {
          routeName: 'dashboard',
        },
        canActivate: [AccessGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'consent',
        data: {
          routeName: 'consent',
        },
        canActivate: [AccessGuard],
        loadChildren: () => import('./modules/consent/consent.module').then(m => m.ConsentModule),
      },
      {
        path: 'campaigns',
        canActivate: [AccessGuard],
        data: {
          routeName: 'campaigns',
        },
        loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule),
      },
      {
        path: 'meetings',
        canActivate: [AccessGuard],
        data: {
          routeName: 'meetings',
        },
        loadChildren: () => import('./modules/meetings/meetings.module').then(m => m.MeetingsModule),
      },
      {
        path: 'download',
        canActivate: [AccessGuard],
        data: {
          routeName: 'download',
        },
        loadChildren: () => import('./download/download.module').then(m => m.DownloadModule),
      },
      {
        path: 'contacts',
        canActivate: [AccessGuard],
        data: {
          routeName: 'contacts'
        },
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'groups',
        data: {
          routeName: 'groups'
        },
        canActivate: [AccessGuard],
        loadChildren: () => import('./modules/group/group.module').then(m => m.GroupModule),
      },
      {
        path: 'segments',
        data: {
          routeName: 'segments'
        },
        canActivate: [AccessGuard],
        loadChildren: () => import('./modules/segment/segment.module').then(m => m.SegmentModule),
      },
      {
        path: 'specializations',
        data: {
          routeName: 'specializations'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/specialization/specialization.module').then(
            m => m.SpecializationModule,
          ),
      },
      {
        path: 'leads',
        data: {
          routeName: 'leads'
        },
        canActivate: [AccessGuard],
        loadChildren: () => import('./modules/lead/lead.module').then(m => m.LeadModule),
      },
      {
        path: 'workplaces',
        data: {
          routeName: 'workplaces'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/workplace/workplace.module').then(m => m.WorkplaceModule),
      },
      {
        path: 'dashboard',
        data: {
          routeName: 'dashboard'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'batch-job',
        data: {
          routeName: 'batch-job'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/batch-jobs/batch-jobs.module').then(m => m.BatchJobsModule),
      },
      {
        path: 'custom-fields',
        data: {
          routeName: 'custom-fields'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/custom-field/custom-field.module').then(m => m.CustomFieldModule),
      },
      {
        path: 'billing',
        data: {
          routeName: 'billing'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/billing/billing.module').then(m => m.BillingModule),
      },
      {
        path: 'knowhow',
        data: {
          routeName: 'knowhow'
        },
        canActivate: [AccessGuard],
        loadChildren: () =>
          import('./modules/knowhow/knowhow.module').then(m => m.KnowhowModule),
      }
    ]
  },
  {
    path: 'authenticate',
    loadChildren: () => import('./modules/authenticate/authenticate.module').then(m => m.AuthenticateModule),
  },
  {
    path: 'logout',
    redirectTo: 'authenticate/logout',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'authenticate',
  }
];
