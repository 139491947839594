import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import introspectionResult from './shared/graphql/introspection-result';

const apiConfiguration = {
  queryUrl: '/api/graphql'
};



export function createApollo(httpLink: HttpLink) {
  const link = httpLink.create({ uri: apiConfiguration.queryUrl });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
      },
    };
  });

  return {
    link: authLink.concat(link),
    cache: new InMemoryCache({possibleTypes: introspectionResult.possibleTypes}),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {
  // constructor(
  //   apollo: Apollo,
  //   httpLink: HttpLink,
  //   private http: HttpClient,
  //   private tokenService: TokenService,
  // ) {
  //   const errorLink = onError(({ networkError, operation, forward }) => {
  //     if (networkError && (networkError as HttpErrorResponse).status === 401) {
  //       if (environment.production) {
  //         tokenService.cleanupCookies();
  //         window.location.href = '/authenticate/login';
  //       }
  //       return promiseToObservable(this.getNewToken()).flatMap((token: RefreshTokenResponse) => {
  //         const headers = new HttpHeaders().set('Authorization', `Bearer ${token.access_token}`);
  //         operation.setContext({ headers });
  //         return forward(operation);
  //       });
  //     }
  //   });
  //   let { access_token } = this.tokenService.getToken();
  //   if (!access_token) {
  //     const appRootElement = document.querySelector('app-root');
  //     access_token = appRootElement.getAttribute('authtoken');
  //   }
  //
  //   const apiLink = httpLink.create({
  //     uri: '/api/graphql',
  //     headers: new HttpHeaders().set('Authorization', `Bearer ${access_token}`),
  //   });
  //
  //   const operationMiddleware = new ApolloLink((operation, forward) => {
  //     operation.setContext(() => {
  //       return { uri: `/api/graphql#${operation.operationName}` };
  //     });
  //     return forward(operation);
  //   });
  //
  //   apollo.create({
  //     link: from([errorLink, concat(operationMiddleware, apiLink)]),
  //     cache: new InMemoryCache({possibleTypes: introspectionResult.possibleTypes}),
  //     defaultOptions: {
  //       watchQuery: {
  //         errorPolicy: 'all',
  //         fetchPolicy: 'no-cache',
  //       },
  //       mutate: {
  //         errorPolicy: 'all',
  //         fetchPolicy: 'no-cache',
  //       },
  //       query: {
  //         errorPolicy: 'all',
  //         fetchPolicy: 'no-cache',
  //       },
  //     },
  //   });
  // }
  //
  // private getNewToken(): Promise<RefreshTokenResponse> {
  //   const payload = new HttpParams().set(
  //     'refresh_token',
  //     this.tokenService.getToken().refresh_token,
  //   );
  //   return this.http
  //     .post('/api/refresh/token', payload)
  //     .pipe(
  //       take(1),
  //       map(({ access_token, refresh_token }: RefreshTokenResponse) => ({
  //         access_token,
  //         refresh_token,
  //       })),
  //     )
  //     .toPromise();
  // }
}

// const promiseToObservable = promise => {
//   return new Observable(subscriber => {
//     promise.then(
//       value => {
//         if (subscriber.closed) {
//           return;
//         }
//         subscriber.next(value);
//         subscriber.complete();
//       },
//       err => {
//         subscriber.error(err);
//       },
//     );
//   });
// };
// interface RefreshTokenResponse {
//   access_token: string;
//   refresh_token: string;
// }
