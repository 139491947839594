import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler extends MissingTranslationHandler {
  private warns = new Set<string>();

  handle({ key, translateService }: MissingTranslationHandlerParams): any {
    const lang = translateService.currentLang;
    const warn = `Missing ${lang} translation: ${key}`;
    if (!this.warns.has(warn)) {
      console.warn(warn);
      this.warns.add(warn);
    }
    return key;
  }
}
