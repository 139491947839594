import { APP_BASE_HREF, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeTr from '@angular/common/locales/tr';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ACCESS_CONFIG, BASIC_TOKEN } from '@cm/ui-modules';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AccessConfig } from './acccess.config';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { GraphQLModule } from './graphql.module';
import { CoreModule } from './modules/core/core.module';
import { MENU_PROVIDER } from './shared/config/menu.config';
import { ConfigService } from './shared/services/config.service';
import { GlobalTimezoneService } from './shared/services/global-timezone.service';
import { HASHAUTHTOKEN } from './shared/services/hashaccess';
import { SharedModule } from './shared/shared.module';
import { TRANSLATE_MODULE_CONFIG } from './translation/translation-config';
import { InactivityModalComponent } from '@shared/components/inactivity-modal/inactivity-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';

export function initializeAppFactory(configService: ConfigService) {
  return () => initializeApp(configService);
}
export function initializeApp(configService: ConfigService): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      configService.initAppConfig();
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeDe);
registerLocaleData(localeTr);
registerLocaleData(localeEs);

const HASHACCESS_PROVIDER: Provider = {
  provide: HASHAUTHTOKEN,
  useValue: environment.hashauthtoken
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
    RouterModule.forRoot(ROUTES),
    GraphQLModule,
    SharedModule.forRoot(),
    CoreModule,
    HttpClientModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    CookieService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [ConfigService],
      multi: true
    },
    HASHACCESS_PROVIDER,
    DatePipe,
    GlobalTimezoneService,
    {
      provide: ACCESS_CONFIG,
      useValue: AccessConfig
    },
    {
      provide: BASIC_TOKEN,
      useValue: btoa(environment.basic_token_user.name + ':' + environment.basic_token_user.password)
    },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    MENU_PROVIDER

  ],
})
export class AppModule { }
