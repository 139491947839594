import { HttpClient } from '@angular/common/http';
import { Provider } from '@angular/core';
import { MissingTranslationHandler, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DEPLOY_URL } from '../core/injectors';
import { CustomMissingTranslationHandler } from './services/custom-missing-translation-handler';

export function httpLoaderFactory(httpClient: HttpClient, deployUrl: string): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, `${deployUrl}assets/i18n/`);
}

export const TRANSLATE_LOADER_PROVIDER: Provider = {
  provide: TranslateLoader,
  useFactory: httpLoaderFactory,
  deps: [HttpClient, DEPLOY_URL],
};

export const MISSING_TRANSLATION_HANDLER_PROVIDER: Provider = {
  provide: MissingTranslationHandler,
  useClass: CustomMissingTranslationHandler,
};

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: TRANSLATE_LOADER_PROVIDER,
  missingTranslationHandler: MISSING_TRANSLATION_HANDLER_PROVIDER,
};
