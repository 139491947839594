
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccountBalanceChangedEvent": [
      "AddAccountBalanceChangedEventGraphQLDTO",
      "AutomatorAccountBalanceChangedEvent",
      "InsightAccountBalanceChangedEvent",
      "InsightUserAccountChangeBalanceChangedEvent",
      "InternalUserAccountBalanceChangedEvent",
      "MessageAccountBalanceChangedEvent",
      "MessageUserAccountChangeBalanceChangedEvent",
      "PresentationAccountBalanceChangedEvent",
      "PresentationUserAccountAddBalanceChangedEvent",
      "UpdateAccountBalanceChangedEventGraphQLDTO"
    ],
    "AutomatorFieldValidationException": [
      "AutomatorDynamicVariableDoesntExistException",
      "AutomatorEmptyUpdateObjectActionException",
      "AutomatorFieldIsNullException",
      "AutomatorInvalidFieldValueException",
      "AutomatorLiteralFieldValueInDataFieldInvalidException",
      "AutomatorObjectFieldNameDoesntExistException",
      "AutomatorObjectNameDoesntExistException",
      "AutomatorRelationCommandException",
      "AutomatorTargetUrlInsecureException",
      "AutomatorWebhookSchemaFieldTypeIsInvalidException",
      "AutomatorWebhookSchemaNestingLevelTooHighException"
    ],
    "AutomatorTopologyValidationException": [
      "AutomatorEndsWithWrongWorkflowElementTypeException",
      "AutomatorFeedbackLoopBetweenWorkflowsException",
      "AutomatorFeedbackLoopInWorkflow",
      "AutomatorGraphDisconnectedException",
      "AutomatorIllegalLoopInWorkflowException",
      "AutomatorMergeAfterChoiceException",
      "AutomatorNoElementsException",
      "AutomatorUnexpectedInDegreeException"
    ],
    "AutomatorWorkflowElement": [
      "AutomatorConnectionAddedEventTriggerWorkflowElement",
      "AutomatorConnectionRemovedEventTriggerWorkflowElement",
      "AutomatorCreateObjectActionWorkflowElement",
      "AutomatorDelayFlowWorkflowElement",
      "AutomatorDeleteObjectActionWorkflowElement",
      "AutomatorGetObjectListActionWorkflowElement",
      "AutomatorGetOneObjectActionWorkflowElement",
      "AutomatorHasCompoundFilterFlowWorkflowElement",
      "AutomatorHasDynamicSelectionActionWorkflowElement",
      "AutomatorHasSubstitutionTagsActionWorkflowElement",
      "AutomatorLocalChoiceFlowWorkflowElement",
      "AutomatorLocalFlowFilterFlowWorkflowElement",
      "AutomatorMeetingEventTriggerWorkflowElement",
      "AutomatorMessageEventTriggerWorkflowElement",
      "AutomatorObjectCreatedEventTriggerWorkflowElement",
      "AutomatorObjectDeletedEventTriggerWorkflowElement",
      "AutomatorObjectUpdatedEventTriggerWorkflowElement",
      "AutomatorQueryObjectExistsActionWorkflowElement",
      "AutomatorSendEmailActionWorkflowElement",
      "AutomatorSendMessageActionWorkflowElement",
      "AutomatorSendSmsActionWorkflowElement",
      "AutomatorSfdcAddToNestedUploadJobActionWorkflowElement",
      "AutomatorSfdcAddToUploadJobActionWorkflowElement",
      "AutomatorSfdcObjectCreatedEventTriggerWorkflowElement",
      "AutomatorSfdcObjectDeletedEventTriggerWorkflowElement",
      "AutomatorSfdcObjectUndeletedEventTriggerWorkflowElement",
      "AutomatorSfdcObjectUpdatedEventTriggerWorkflowElement",
      "AutomatorSfdcUserAccountConnectionAddedEventTriggerWorkflowElement",
      "AutomatorSfdcUserAccountConnectionRemovedEventTriggerWorkflowElement",
      "AutomatorTimeEventTriggerWorkflowElement",
      "AutomatorUpdateObjectActionWorkflowElement",
      "AutomatorWaitForMessageEventFlowWorkflowElement",
      "AutomatorWebhookActionWorkflowElement",
      "AutomatorWebhookEventTriggerWorkflowElement"
    ],
    "AutomatorWorkflowElementExecutionException": [
      "AutomatorCampaignNotFoundExecutionException",
      "AutomatorCouldNotBuildRequestBodyExecutionException",
      "AutomatorCouldNotConvertInputDataExecutionException",
      "AutomatorCouldNotCreateDynamicTriggerExecutionException",
      "AutomatorCouldNotScheduleDelayExecutionException",
      "AutomatorCurrentDataIsMissingExecutionException",
      "AutomatorExternalServiceInternalErrorExecutionException",
      "AutomatorExternalServiceUnavailableExecutionException",
      "AutomatorGotEmptyResultSet",
      "AutomatorGotMoreThanOneObject",
      "AutomatorInputValidationExecutionException",
      "AutomatorMalformedURLExecutionException",
      "AutomatorObjectNameNotFoundExecutionException",
      "AutomatorObjectOfGivenIdNotFoundExecutionException",
      "AutomatorObjectPropertyNotFoundExecutionException",
      "AutomatorOldDataIsMissingExecutionException",
      "AutomatorOptimisticLockingExecutionException",
      "AutomatorReceivedPayloadWithInvalidSchemaExecutionException",
      "AutomatorRecipientNotFoundExecutionException",
      "AutomatorResponseTooLargeExecutionException",
      "AutomatorSenderNotFoundExecutionException",
      "AutomatorSubstitutionValueIsNullException",
      "AutomatorUnknownExecutionException",
      "AutomatorUnspecifiedExternalExecutionException",
      "AutomatorUnspecifiedInternalExecutionException",
      "AutomatorVariableCouldNotBeConvertedExecutionException",
      "AutomatorVariableValueIsAlreadySetExecutionException",
      "AutomatorVariableValueOfGivenIdNotFoundExecutionException",
      "AutomatorWebhookErrorResponseExecutionException",
      "AutomatorWebhookSchemaInvalidExecutionException",
      "AutomatorWorkflowElementNotValidExecutionException"
    ],
    "ObjectsList": [
      "BatchJobList",
      "ContactList",
      "CustomActionDefinitionList",
      "CustomFieldDefinitionList",
      "GroupList",
      "LeadList",
      "MeetingEventList",
      "MeetingList",
      "MessageCampaignList",
      "MessageElementList",
      "MessageEventList",
      "MessageList",
      "PresentationChapterList",
      "PresentationList",
      "PresentationSlideList",
      "ProductManagerList",
      "RepresentativeList",
      "SpecializationList",
      "WorkplaceList"
    ]
  }
};
      export default result;
    